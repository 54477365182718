import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import TurnaroundTime from './pages/Turnaround';
import EditUser from './pages/EditUser';
import {ProvideAuth,useAuth} from './hooks/useAuth';

// ----------------------------------------------------------------------

export default function Router() {

  const user = useAuth();
  return useRoutes([
    {
      path: '/dashboard',
      element: <ProvideAuth><DashboardLayout /></ProvideAuth>,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
         { path: 'user/edit-user', element: <EditUser /> },
        { path: 'turnaround-time', element: <TurnaroundTime /> },
        { path: 'user/create-user', element: <Register /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
