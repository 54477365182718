import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate ,useLocation} from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment ,MenuItem,Divider,Grid} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../components/Iconify';
import { FormProvider, RHFTextField } from '../../components/hook-form';
// apis
import userApi from '../../apis/userApi';

// ----------------------------------------------------------------------

export default function EditUserForm({user}) {
  const navigate = useNavigate();

  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    userName:Yup.string().required('Username required'),
    role:Yup.string().required('Role is required'),
    depot: Yup.string().required('Depot is required')
  });
  const {id,firstName,lastName,email,depot,role,userName} = location?.state;
  const defaultValues = {
    firstName,
    lastName,
    userName,
    email,
    password: '',
    depot,
    role
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (newUser) => {
   try{
    const update = await userApi.editUserDetail(id,newUser);
    if(update){
      navigate('/dashboard/user', { replace: true });
    }
   }catch(error){
      alert("Could not update user detail")
   }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
          <RHFTextField name="userName" label="Username" />

        </Stack>

        <RHFTextField name="email" label="Email address" />

      
         <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="depot" label="Depot" select>
            {
              ['APD','AKOSOMBO','BOLGA','BUIPE','KUMASI'].map((depot,index)=>(
                  <MenuItem key={index} value={depot}>{depot}</MenuItem>
                ))
            }
          </RHFTextField>
          <RHFTextField name="role" label="Role" select>
             {
              ['Admin','User'].map((role,index)=>(
                  <MenuItem key={index} value={role}>{role}</MenuItem>
                ))
            }
          </RHFTextField>

        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Save
        </LoadingButton>
        <Divider/>
       {  /* <Grid container direction='row' spacing={3}>
          <Grid item xs={10}>
            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
           <LoadingButton fullWidth size="large" type="button" variant="contained" loading={isSubmitting}>
            Save
          </LoadingButton>
          </Grid>
        </Grid>
     */ }
      </Stack>
    </FormProvider>
  );
}
