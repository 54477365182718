import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const _users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.findName(),
  company: faker.company.companyName(),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer',
  ]),
}));

const users = [...Array(24)].map((_, index)=>({
  id: faker.datatype.uuid(),
  avatarUrl:`/static/mock-images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.findName(),
  userName: faker.name.firstName(),
  email: sample([
    'test@mail.com',
    'kofi@bost.com'
  ]),
  role:sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer',
  ]),
  depot:sample([
    'APD',
    'Kumasi',
    'Bolga',
    'Buipe',
    'Akosombo'

  ]),
  lastUpdated: faker.date.past().toString(),
  status:sample(['active', 'banned']),
}))
export default users;
