import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CSVLink, CSVDownload } from 'react-csv';
import {format} from 'date-fns';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  LinearProgress 
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { TurnaroundListHead, TurnaroundListToolbar, TurnaroundMoreMenu } from '../sections/@dashboard/turnaround';
import TurnaroundFilterForm from '../sections/@dashboard/turnaround/TurnaroundFilterForm';

import turnaroundApi from '../apis/turnaroundApi';

// ----------------------------------------------------------------------

const TABLE_HEAD =[
 { id: 'brvNumber', label: 'BRV #', alignRight: false },
 { id: 'date', label: 'Date', alignRight: false },
 { id: 'depot', label: 'Depot', alignRight: false },
 { id: 'capacity', label: 'Capacity', alignRight: false },
 { id: 'entryTime', label: 'Entry', alignRight: false },
 { id: 'exitTime', label: 'Exit', alignRight: false },
 { id: 'turnaroundTime', label: 'Turnaround', alignRight: false },
 { id: 'operation', label: 'Operation', alignRight: false },
 { id: '' }
];

//------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TurnaroundTime() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [LIST, setTurnaroundList] = useState([]);

  const [turnaroundData, setTurnaroundData] = useState([]);

  const [open, setOpen] = useState(false);

  const [requesting, setRequesting] = useState(false);

  const [csv, setCSV] = useState([]);
  // useEffect
  useEffect(()=>{
    setRequesting(true);
      (async function(){
        const res = await turnaroundApi.getAllTurnaroundTime();
        setTurnaroundList(res.data); 
        setTurnaroundData(res.data);
        formatCSV(res.data);
        setRequesting(false);
      })()
      return () => {};
  },[]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = LIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LIST.length) : 0;

  const filteredUsers = applySortFilter(LIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  
  const formatCSV=(list)=>{
   
    const csvData =[
      [`Date`,`BRV #`,`Depot`,`Capacity`,`Entry`,`Exit`,`Turnaround`,`Operation`],
    ];
    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < list.length; i++ ) {
      const tmp = list[i];
      csvData.push([
        `${format(new Date(tmp.createdAt), 'dd/MM/yyyy')}`,
        `${tmp.brv}`,
        `${tmp.depot}`,
        `${tmp.capacity&&tmp.capacity.toLocaleString("en")}`,
        `${tmp.entrytime}`,
        `${tmp.exittime}`,
        `${timeConvert(tmp.turnaroundtime&&tmp.turnaroundtime)}`,
        `${tmp.operation&&tmp.operation?.replace(/_/g," ")}`,
      ])
    }
    setCSV(csvData);
  }
  const closeModal=()=>{
    setOpen(!open)
  };
 const epoch = Math.round(Date.now() / 1000);
  const handleDelete =async(rowId)=>{
    try{
   
      const res = await turnaroundApi.deleteTurnaround(rowId);
      if(res){
        const index = LIST.findIndex((arr)=>arr.id);
        const _index =LIST.indexOf(rowId);
      
      }
    }catch(error){
      alert('Something went wrong !')
    }
  }

 function timeConvert(num) {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return `${hours} h ${minutes} m`;
  }

 const handleFilter =async(data)=>{
    try{
      const res = await turnaroundApi.filterTurnaround(data);
      if(res){
        setTurnaroundData(res.data);
        setTurnaroundList(res.data);
        formatCSV(res.data)
        
      }

    }catch(error){
      alert('Something went wrong !')
    }
    setOpen(false)
  }

  return (
    <Page title="Turnaround time">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Turnaround Time
          </Typography>
           <CSVLink data={csv} filename={`BOST-BRV-${epoch}.csv`}>Export</CSVLink>;
         <Button variant="contained" onClick={()=>setOpen(!open)} startIcon={<Iconify icon="eva:funnel-fill" />}>
            Filter
          </Button> 
        </Stack>

       <Modal open={open} onClose={closeModal}>
          <TurnaroundFilterForm close={closeModal} handleFilterSubmit={handleFilter}/>
       </Modal>
        <Card>
        {requesting&& <LinearProgress/>}
          <TurnaroundListToolbar
           numSelected={selected.length}
           filterName={filterName}
           onFilterName={handleFilterByName} 
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TurnaroundListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, date,createdAt,depot, brv, capacity, entrytime, exittime, turnaroundtime, operation} = row;
                    
                    const isItemSelected = selected.indexOf(brv) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, brv)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {brv}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{format(new Date(createdAt), 'dd/MM/yyyy')}</TableCell>
                        <TableCell align="left">{depot}</TableCell>
                        <TableCell align="left">{capacity&&capacity.toLocaleString("en")}</TableCell>
                        <TableCell align="left">{entrytime}</TableCell>
                        <TableCell align="left">{!exittime?'PENDING':exittime}</TableCell>
                        <TableCell align="left">{timeConvert(turnaroundtime&&turnaroundtime)}</TableCell>
                        <TableCell align="left">{operation&&operation?.replace(/_/g," ")}</TableCell>
                        <TableCell align="right">
                          <TurnaroundMoreMenu rowId={id} handleDelete={handleDelete}/>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
